import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Constant } from 'src/app/constants/constant';
import { NewSubscriptionService } from 'src/app/services/new-subscription.service';
import { SubscriptionPriceService } from 'src/app/services/subscription-price.service';

@Component({
  selector: 'calsoft-subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrls: ['./subscription-plan.component.scss']
})
export class SubscriptionPlanComponent {

  @Input() type: any = 1;

  
  @Input() SuccessMessage: any = 'Form Submitted SuccessFully';

  @Input() FailerMessage: any = 'Form Submitted Failed';

  @Input() redirectUrl: any = '/home';

  subscriptionCost: any = 0.0;
  taxPercentage: any = 0.0;
  totalCost: any = 0.0;
  taxAmount: any = 0.0;
  totalGrandTotal: any = 0.0;
  currentMap: Map<string, any> = new Map();
  totalTax: number;
  totalGrandTotalWithoutTax: any = 0.0;
  form: FormGroup;

  formValid: boolean = false;
  subscriptionType: any;
  subscriptionImgName: any;

  referer: string;

  constructor(private subscriptionService: SubscriptionPriceService,
    private newSubscriptionService: NewSubscriptionService,
    private snackBar: MatSnackBar,
    private router: Router,
  ) { }
  contact: {};
  ngOnInit() {

    this.referer=Constant.REFERER;

    this.subscriptionService.subscriptionPrice$.subscribe(price => {
      this.subscriptionCost = Number(price);


    });

    this.subscriptionService.subscriptionTaxPercentage$.subscribe(taxPercentage => {
      this.taxPercentage = taxPercentage;
    });

    this.subscriptionService.subscriptionTotalCost$.subscribe(totalCost => {
      this.totalCost = totalCost;
    });

    this.subscriptionService.subscriptionTotalTaxAmount$.subscribe(taxAmount => {
      this.taxAmount = taxAmount;
      this.taxAmount = Number(taxAmount);
    });

    this.subscriptionService.GrandTotalAmount$.subscribe(totalGrandTotal => {
      this.totalGrandTotal = totalGrandTotal;
    });

    this.subscriptionService.subscriptionType$.subscribe(subscriptionType => {
      this.subscriptionType = subscriptionType;
    });

    this.subscriptionService.subscriptionImgName$.subscribe(subscriptionImgName => {
      this.subscriptionImgName = subscriptionImgName;
    });


    this.subscriptionService.form$.subscribe(form => {

      if (form != null) {
        this.form = form;
        if (this.form.valid) {

          let object = {
            "name": this.form.value.name,
            "contact": this.form.value.phoneNumber,
            "email": this.form.value.email,
          }
          this.contact = object;
          this.formValid = true;
        }


      }

    });
    this.subscriptionService.getMap().subscribe(map => {
      this.currentMap = map;
      this.totalGrandTotal = 0.0;
      this.totalTax = 0.0;
      this.totalGrandTotalWithoutTax = 0.0;
      // Iterate over the map and log its keys and values
      this.currentMap.forEach((value: any, key) => {
        // const totalGrandTotal = this.value.reduce((sum, item) => sum + item.grandTotal, 0);
        value.forEach(element => {
          console.log(element);
          this.totalTax = this.totalTax + element.totalTaxAmount * element.quantity;
          this.totalGrandTotalWithoutTax = this.totalGrandTotalWithoutTax + element.rowTotalWithoutTax * element.quantity;
          this.totalGrandTotal = this.totalGrandTotal + element.rowTotal * element.quantity;

        });
      });

      this.totalTax = Number(this.totalTax)
      this.totalGrandTotalWithoutTax = Number(this.totalGrandTotalWithoutTax)
    });

  }

  paymentResponceRazorPAy(i) {
    let payment = 'razorPay';
    let paymentId = i.paypalPaymentStatus;
    let orderTranscationId = i.salesPaymentTransaction[0].txn_id;
    let mode = "razor_pay";
    let amentiesList1 = [];
    let amentiesList2 = [];
    let amentiesList3 = [];
    let index = 0;
    this.currentMap.forEach((value: any, key) => {


      if (index == 0) {

        amentiesList1 = value

      } else if (index == 1) {

        amentiesList2 = value
      } else {
        amentiesList3 = value
      }

      index++;
    });


    let object = {
      paymentId: paymentId,
      paymentTranscId: orderTranscationId,
      mode: "razor_pay",
      subScriptionType: this.subscriptionType,
      additionalAmmenties: 1,
      subScriptionName: this.subscriptionImgName,
      total: this.subscriptionCost + this.totalGrandTotalWithoutTax + this.totalTax + this.taxAmount,
      taxAmount: this.totalGrandTotalWithoutTax + this.totalTax,
      subscriptionCost: this.subscriptionCost,
      ammentiesCost: this.totalGrandTotal,
      amentiesTax: this.totalTax,
      subcribtionTax: this.taxAmount,
      amentiesList1: amentiesList1.length == 0 ? null : JSON.stringify(amentiesList1),
      amentiesList2: amentiesList2.length == 0 ? null : JSON.stringify(amentiesList2),
      amentiesList3: amentiesList3.length == 0 ? null : JSON.stringify(amentiesList3),
      form: JSON.stringify(this.form.value),

      
      paymentInformation: JSON.stringify({
        paymentId: paymentId,
        paymentTranscId: orderTranscationId,
        mode: "razor_pay"
    })
    }



    if (object != null) {
      this.finalSumbit(object);
    }


  }


  text: string;




  totalAmount(): number {
    return this.subscriptionCost + this.totalGrandTotalWithoutTax + this.totalTax;
  }

  finalSumbit(object) {

    if (object != null) {

      let formData = JSON.parse(object.form);

      let saveForm = {
        name: formData.name,
        contact: formData.phoneNumber,
        email: formData.email,
        accountNo: formData.accountNo,
        ifscCode: formData.bankIfscCode,
        gstNo: formData.gstNo,
        kycImg: formData.kycImg,
        panCard: formData.panCard,
        fassai: formData.fassai,
        shopEstablish: formData.shopEstablish,
        customField1: formData.customField1,
        customField2: formData.customField2,
        customField3: formData.customField3,
        customField4: formData.customField4,
        customField5: formData.customField5,
        customField6: formData.customField6,
        customField7: formData.customField7,
        customField8: formData.customField8,
        customField9: formData.customField9,
        customField10: object.paymentInformation,
        value: JSON.stringify(object),
      };

      if (saveForm != null) {
        this.newSubscriptionService.saveSubscriptionForm(saveForm).subscribe(
          (response) => {

            if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
              this.snackBar.open(this.SuccessMessage, '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
              
              if (this.redirectUrl.includes('http://') || this.redirectUrl.includes('https://')) {
                window.open(this.redirectUrl, '_blank');
              }else{
                this.router.navigate([this.redirectUrl]);
              }
            
             
            }
            else {
              this.snackBar.open(this.FailerMessage, '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
            }
          },
          (err) => { "object is null" });
      }
      else {
        (err) => {
          console.error('Error occurred:', err);
          this.snackBar.open('Failed to save data', '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
        }

      }
    }
  }


}

